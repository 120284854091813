import { defineStore } from 'pinia';
import { UserStatus } from '~/interfaces/user';
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus';

const runtimeConfig = useRuntimeConfig();

let authKeyLink = '/user/telegram/bot/generate-auth-key';
let checkTgAuthLink = '/user/telegram/bot/check-auth';

let timeoutValueToCheckTg = 60000;
let intervalValueToCheckTg = 3000;

export const useUserStore = defineStore('user', () => {
  let id: Ref<string> = ref('');
  let name: Ref<string> = ref('');
  let status = UserStatus.REGISTERED;
  let telegram: Ref<string> = ref('');
  let email: Ref<string> = ref('');
  let authKey: Ref<string> = ref('');
  let authorized: Ref<string> = ref('');

  let intervalIdToTgCheck: Ref<number> =  ref(0);
  let timeoutIdToTgCheck: Ref<number> =  ref(0);

  let loginDialogVisible: Ref<boolean> = ref(false);

  // 1: запросить host
	// stage-data-light.socialjet.pro
  // /user/telegram/bot/generate-auth-key
  function resetAuthKey() {
    authKey.value = '';
    authorized.value = '';
    id.value = '';
    setLS();
  } // resetAuthKey

  function setAuthorized(key: string, auth: string, id_v: string) {
    authKey.value = key;
    authorized.value = auth;
    id.value = id_v;
    setLS();
  } // setAuthorized

  function setLS() {
    localStorage.setItem('useUserStore__authKey', authKey.value);
    localStorage.setItem('useUserStore__authorized', authorized.value);
    localStorage.setItem('useUserStore__id', id.value);
  } // setLS

  function requestAuthKey() {
    const useUserStore__authorized = localStorage.getItem('useUserStore__authorized');
    if (useUserStore__authorized) {
      authorized.value = useUserStore__authorized
    }
    const useUserStore__authKey = localStorage.getItem('useUserStore__authKey');
    if (useUserStore__authKey) {
      authKey.value = useUserStore__authKey
    }
    const useUserStore__id = localStorage.getItem('useUserStore__id');
    if (useUserStore__id) {
      id.value = useUserStore__id
    }
    if (authorized.value) {
      return;
    }
    if (authKey.value) {
      return;
    }
    useFetch(authKeyLink, {
      baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
      method: 'POST',
      onRequest({ request, options }) {
        // Set the request headers
        // options.headers = options.headers || {}
        // options.headers.authorization = '...'
      },
      onRequestError({ request, options, error }) {
        // Handle the request errors
      },
      onResponse({ request, response, options }) {
        // Process the response data
        // TODO: remove console.log
        console.log(response);
        authKey.value = response._data.authKey;
        localStorage.setItem('useUserStore__authKey', authKey.value);
      },
      onResponseError({ request, response, options }) {
        // Handle the response errors
      }
    })
  } // requestAuthKey

  function startTelegramAuthorize() {
    ElMessageBox.confirm(
      /* title: */'Вход через Telegram',
      /* message: */'Перейдите в Telegram и нажмите start в боте',
    {
      showCancelButton: false,
      confirmButtonText: 'OK',
    }).then(() => {
      const url = `${useRuntimeConfig().public.RESOLVE_TELEGRAM_AUTHORIZE_PREFIX}${authKey.value}`;
      console.log('check url for telegram bot login:');
      console.log(url);
      window.open(url, '_blank');
      loginDialogVisible.value = false;
      checkTelegramAuthorize();
    })
  } // startTelegramAuthorize

  function checkTelegramAuthorize() {
    // new check
    clearInterval(intervalIdToTgCheck.value);
    clearTimeout(timeoutIdToTgCheck.value);

    timeoutIdToTgCheck.value = window.setTimeout(() => {
      clearInterval(intervalIdToTgCheck.value);
    }, timeoutValueToCheckTg);

    intervalIdToTgCheck.value = window.setInterval(() => {
      if ((authorized.value) && (id.value)) {
        clearInterval(intervalIdToTgCheck.value);
      }
      useFetch(checkTgAuthLink, {
        baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
        method: 'GET',
        query: {
          authKey: authKey.value,
        },
        onRequest({ request, options }) {
        },
        onRequestError({ request, options, error }) {
        },
        onResponse({ request, response, options }) {
          console.log(response);          
          // { id: number; accessToken: string }
          if ((response._data.accessToken) && (response._data.id)) {
            authorized.value = response._data.accessToken;
            id.value = response._data.id;
            ElNotification({
              title: 'Успешная авторизация через Telegram',
              message: 'Спасибо!',
              type: 'success',
            });
            setLS();
            clearInterval(intervalIdToTgCheck.value);
            clearTimeout(timeoutIdToTgCheck.value);
          } else {
            ElNotification({
              title: 'Ожидание авторизации',
              message: 'Ждём подтверждения от бота...',
              type: 'warning',
            })
          }
        },
        onResponseError({ request, response, options }) {
        }
      })
    }, intervalValueToCheckTg);
  } // checkTelegramAuthorize


  return { 
    id,
    name,
    status,
    telegram,
    email,
    authKey,
    authorized,
    loginDialogVisible,
    intervalIdToTgCheck,
    timeoutIdToTgCheck,
    setLS,
    requestAuthKey,
    resetAuthKey,
    setAuthorized,
    startTelegramAuthorize,
    checkTelegramAuthorize
  }
});
